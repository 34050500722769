exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-js": () => import("./../../../src/pages/about-us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-admin-js": () => import("./../../../src/pages/admin.js" /* webpackChunkName: "component---src-pages-admin-js" */),
  "component---src-pages-app-js": () => import("./../../../src/pages/app.js" /* webpackChunkName: "component---src-pages-app-js" */),
  "component---src-pages-events-js": () => import("./../../../src/pages/events.js" /* webpackChunkName: "component---src-pages-events-js" */),
  "component---src-pages-iamanathlete-js": () => import("./../../../src/pages/iamanathlete.js" /* webpackChunkName: "component---src-pages-iamanathlete-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-support-js": () => import("./../../../src/pages/support.js" /* webpackChunkName: "component---src-pages-support-js" */),
  "component---src-pages-terms-privacy-policy-js": () => import("./../../../src/pages/terms-privacy-policy.js" /* webpackChunkName: "component---src-pages-terms-privacy-policy-js" */),
  "component---src-templates-professionals-post-js": () => import("./../../../src/templates/professionals-post.js" /* webpackChunkName: "component---src-templates-professionals-post-js" */)
}

