/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/reference/config-files/gatsby-browser/
 */

// You can delete this file if you're not using it

import React from "react"
import "firebase/auth"
import "firebase/firestore"
import "firebase/functions"

import './src/styles/globals.css'

import AuthProvider from "./src/components/Auth/AuthContext";

export const wrapRootElement = ({ element }) => (
    <AuthProvider >
        {element}
    </AuthProvider>
); 